
@media (min-width:1024px) {
    

.banner-slider{
    height: 650px;
    overflow: hidden;
    position: relative;
}
}
.banner-slider img{
    transition-timing-function: linear;
    transition-duration: 20s;
    transform: scale(1);
}
.slick-active .banner-slider img{
    transform: scale(1.2);
}
.banner-slider-section .banner-slider::before {
    content: "";
    top: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .banner-text {
    margin-left: 140px;
    padding-top: 0px;
}
@media screen and (max-width: 991px) {
    
    .banner-text {
        margin-left: 10px;
    }
} */
.slide {
    width: 100%!important;
}
.slick-dots{
    bottom: 25px;
}
.slick-dots li button::before{
    color: #fff;
    font-size: 14px;
}
.slick-dots .slick-active button::before{
    opacity: 1;
    color: #fff !important;
}