.form-input:focus{
    box-shadow: 0 0 0 1px #8F6445, 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-color: #8F6445;
}
/* .PhoneInputCountry {
    display: none !important;
} */

input.align-middle.size-4{
    scale: 1.5;
     margin-right: 12px;
 }
 
